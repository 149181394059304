import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from "@mui/material"
function FaqPage() {
  const contents = [
    {
      id: 1,
      title: "Legal",
      accordion: [
        {
          question:
            "How does the app handle my personal data and plant-related information?",
          answer:
            "Lorem ipsum dolor sit amet consectetur. Porttitor dui egestas sed tellus quis euismod. Nulla tincidunt velit est velit aliquet eu in. Imperdiet est massa fusce in. Enim velit dignissim porttitor vulputate nisi vitae eget nibh. Ipsum enim in etiam tellus ac. In erat mollis etiam aliquam. Tempus enim tincidunt tristique sed nibh in a et. Elementum amet faucibus molestie vestibulum lacus felis feugiat dolor scelerisque. Amet luctus porttitor ullamcorper donec amet. Cras praesent et est volutpat. Adipiscing sem aliquet ut dolor quis. Luctus nascetur tellus tincidunt malesuada in ipsum amet pharetra. Integer diam lectus ac id auctor.",
        },
        {
          question: "Is my data securely stored and protected?",
          answer:
            "Lorem ipsum dolor sit amet consectetur. Porttitor dui egestas sed tellus quis euismod. Nulla tincidunt velit est velit aliquet eu in. Imperdiet est massa fusce in. Enim velit dignissim porttitor vulputate nisi vitae eget nibh. Ipsum enim in etiam tellus ac. In erat mollis etiam aliquam. Tempus enim tincidunt tristique sed nibh in a et. Elementum amet faucibus molestie vestibulum lacus felis feugiat dolor scelerisque. Amet luctus porttitor ullamcorper donec amet. Cras praesent et est volutpat. Adipiscing sem aliquet ut dolor quis. Luctus nascetur tellus tincidunt malesuada in ipsum amet pharetra. Integer diam lectus ac id auctor.",
        },
        {
          question:
            "Will my plant care data be shared with third parties or used for marketing purposes?",
          answer:
            "Lorem ipsum dolor sit amet consectetur. Porttitor dui egestas sed tellus quis euismod. Nulla tincidunt velit est velit aliquet eu in. Imperdiet est massa fusce in. Enim velit dignissim porttitor vulputate nisi vitae eget nibh. Ipsum enim in etiam tellus ac. In erat mollis etiam aliquam. Tempus enim tincidunt tristique sed nibh in a et. Elementum amet faucibus molestie vestibulum lacus felis feugiat dolor scelerisque. Amet luctus porttitor ullamcorper donec amet. Cras praesent et est volutpat. Adipiscing sem aliquet ut dolor quis. Luctus nascetur tellus tincidunt malesuada in ipsum amet pharetra. Integer diam lectus ac id auctor.",
        },

        {
          question:
            "Can I control the privacy settings of my account and plant data?",
          answer:
            "Lorem ipsum dolor sit amet consectetur. Porttitor dui egestas sed tellus quis euismod. Nulla tincidunt velit est velit aliquet eu in. Imperdiet est massa fusce in. Enim velit dignissim porttitor vulputate nisi vitae eget nibh. Ipsum enim in etiam tellus ac. In erat mollis etiam aliquam. Tempus enim tincidunt tristique sed nibh in a et. Elementum amet faucibus molestie vestibulum lacus felis feugiat dolor scelerisque. Amet luctus porttitor ullamcorper donec amet. Cras praesent et est volutpat. Adipiscing sem aliquet ut dolor quis. Luctus nascetur tellus tincidunt malesuada in ipsum amet pharetra. Integer diam lectus ac id auctor.",
        },
      ],
    },
    {
      id: 2,
      title: "Plant care",
      accordion: [
        {
          question:
            "Can I ask for assistance or seek help from plant experts through the app?",
          answer:
            "Lorem ipsum dolor sit amet consectetur. Porttitor dui egestas sed tellus quis euismod. Nulla tincidunt velit est velit aliquet eu in. Imperdiet est massa fusce in. Enim velit dignissim porttitor vulputate nisi vitae eget nibh. Ipsum enim in etiam tellus ac. In erat mollis etiam aliquam. Tempus enim tincidunt tristique sed nibh in a et. Elementum amet faucibus molestie vestibulum lacus felis feugiat dolor scelerisque. Amet luctus porttitor ullamcorper donec amet. Cras praesent et est volutpat. Adipiscing sem aliquet ut dolor quis. Luctus nascetur tellus tincidunt malesuada in ipsum amet pharetra. Integer diam lectus ac id auctor.",
        },
        {
          question: "Can the app help me diagnose and treat plant issues?",
          answer:
            "Lorem ipsum dolor sit amet consectetur. Porttitor dui egestas sed tellus quis euismod. Nulla tincidunt velit est velit aliquet eu in. Imperdiet est massa fusce in. Enim velit dignissim porttitor vulputate nisi vitae eget nibh. Ipsum enim in etiam tellus ac. In erat mollis etiam aliquam. Tempus enim tincidunt tristique sed nibh in a et. Elementum amet faucibus molestie vestibulum lacus felis feugiat dolor scelerisque. Amet luctus porttitor ullamcorper donec amet. Cras praesent et est volutpat. Adipiscing sem aliquet ut dolor quis. Luctus nascetur tellus tincidunt malesuada in ipsum amet pharetra. Integer diam lectus ac id auctor.",
        },
        {
          question: "Can I care for non ELM plants?",
          answer:
            "Lorem ipsum dolor sit amet consectetur. Porttitor dui egestas sed tellus quis euismod. Nulla tincidunt velit est velit aliquet eu in. Imperdiet est massa fusce in. Enim velit dignissim porttitor vulputate nisi vitae eget nibh. Ipsum enim in etiam tellus ac. In erat mollis etiam aliquam. Tempus enim tincidunt tristique sed nibh in a et. Elementum amet faucibus molestie vestibulum lacus felis feugiat dolor scelerisque. Amet luctus porttitor ullamcorper donec amet. Cras praesent et est volutpat. Adipiscing sem aliquet ut dolor quis. Luctus nascetur tellus tincidunt malesuada in ipsum amet pharetra. Integer diam lectus ac id auctor.",
        },
      ],
    },
    {
      id: 3,
      title: "Account and profile",
      accordion: [
        {
          question:
            "Can I ask for assistance or seek help from plant experts through the app?",
          answer:
            "Lorem ipsum dolor sit amet consectetur. Porttitor dui egestas sed tellus quis euismod. Nulla tincidunt velit est velit aliquet eu in. Imperdiet est massa fusce in. Enim velit dignissim porttitor vulputate nisi vitae eget nibh. Ipsum enim in etiam tellus ac. In erat mollis etiam aliquam. Tempus enim tincidunt tristique sed nibh in a et. Elementum amet faucibus molestie vestibulum lacus felis feugiat dolor scelerisque. Amet luctus porttitor ullamcorper donec amet. Cras praesent et est volutpat. Adipiscing sem aliquet ut dolor quis. Luctus nascetur tellus tincidunt malesuada in ipsum amet pharetra. Integer diam lectus ac id auctor.",
        },
        {
          question: "Can the app help me diagnose and treat plant issues?",
          answer:
            "Lorem ipsum dolor sit amet consectetur. Porttitor dui egestas sed tellus quis euismod. Nulla tincidunt velit est velit aliquet eu in. Imperdiet est massa fusce in. Enim velit dignissim porttitor vulputate nisi vitae eget nibh. Ipsum enim in etiam tellus ac. In erat mollis etiam aliquam. Tempus enim tincidunt tristique sed nibh in a et. Elementum amet faucibus molestie vestibulum lacus felis feugiat dolor scelerisque. Amet luctus porttitor ullamcorper donec amet. Cras praesent et est volutpat. Adipiscing sem aliquet ut dolor quis. Luctus nascetur tellus tincidunt malesuada in ipsum amet pharetra. Integer diam lectus ac id auctor.",
        },
        {
          question: "Can I care for non ELM plants?",
          answer:
            "Lorem ipsum dolor sit amet consectetur. Porttitor dui egestas sed tellus quis euismod. Nulla tincidunt velit est velit aliquet eu in. Imperdiet est massa fusce in. Enim velit dignissim porttitor vulputate nisi vitae eget nibh. Ipsum enim in etiam tellus ac. In erat mollis etiam aliquam. Tempus enim tincidunt tristique sed nibh in a et. Elementum amet faucibus molestie vestibulum lacus felis feugiat dolor scelerisque. Amet luctus porttitor ullamcorper donec amet. Cras praesent et est volutpat. Adipiscing sem aliquet ut dolor quis. Luctus nascetur tellus tincidunt malesuada in ipsum amet pharetra. Integer diam lectus ac id auctor.",
        },
      ],
    },
  ]
  return (
    <Container>
      {contents.map((content, index) => {
        return (
          <Box key={index}>
            <Typography mt={3} mb={1} color={"secondary.light"}>
              {content.title}
            </Typography>
            {content.accordion.map((accordion, index) => (
              <Box key={index}>
                <Accordion
                  square={true}
                  elevation={0}
                  sx={{
                    "&:before": {
                      display: "none",
                    },
                    bgcolor: "primary.light",
                    mb: 0.4,
                  }}
                >
                  <AccordionSummary
                    id="panel1-header"
                    aria-controls="panel1-content"
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography sx={{ display: "list-item", ml: 2 }}>
                      {accordion.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ bgcolor: "secondary.main" }}>
                    <Typography>{accordion.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            ))}
          </Box>
        )
      })}
    </Container>
  )
}

export default FaqPage
