import { Formik } from "formik"

import {
  Box,
  Button,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
import BackgroundLogo from "../../../assets/elm/background.jpg"
import ElmLogo from "../../../assets/elm/logo.png"
import { useAppDispatch, useAppSelector } from "../../hooks"
import {
  selectLoginChallenge,
  selectLoginErrorMessage,
  selectLoginStatus,
} from "./authSlice"
import { login } from "./authThunks"
import { LoginCredentials } from "./types"

export function Login() {
  const dispatch = useAppDispatch()
  const loginErrorMessage = useAppSelector(selectLoginErrorMessage)
  const loginStatus = useAppSelector(selectLoginStatus)
  const loginChallenge = useAppSelector(selectLoginChallenge)

  const initialValues = {
    username: "",
    password: "",
    challenge: loginChallenge,
    newPassword: "",
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      width={"100%"}
      style={{
        backgroundImage: `url(${BackgroundLogo})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
      }}
    >
      <Paper elevation={3} sx={{ borderRadius: 5, width: 400, paddingX: 4 }}>
        <Grid container textAlign={"center"} my={2}>
          <Grid item xs={12}>
            <img
              style={{ height: "80px" }}
              src={ElmLogo}
              alt="Elm Houseplants Logo"
            />
          </Grid>

          <Grid item xs={12} mb={3} mt={-0.5}>
            <Typography variant="caption">Hard to kill houseplants</Typography>
          </Grid>
          <Grid item xs={12} mb={1}>
            <Typography variant="h5">LOG IN</Typography>
          </Grid>
          <Grid item xs={12} alignItems={"center"}>
            <Formik
              initialValues={initialValues}
              onSubmit={(values: LoginCredentials) => {
                dispatch(login(values))
              }}
            >
              {({ values, handleChange, handleBlur, handleSubmit }) => (
                <form
                  onSubmit={handleSubmit}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <TextField
                    margin="normal"
                    type={
                      loginChallenge === "NEW_PASSWORD_REQUIRED"
                        ? "hidden"
                        : "email"
                    }
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="username"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                    autoComplete="email"
                    autoFocus
                    disabled={loginStatus === "loading"}
                  />

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={
                      loginChallenge === "NEW_PASSWORD_REQUIRED"
                        ? "hidden"
                        : "password"
                    }
                    id="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    autoComplete="current-password"
                    disabled={loginStatus === "loading"}
                    helperText="Remember, your password should have at least 8 characters and include at least one number and one special character."
                  />
                  {
                    // Show new password input if challenged at login by Cognito to change password
                    loginChallenge === "NEW_PASSWORD_REQUIRED" ? (
                      <input
                        aria-label="New Password"
                        type="password"
                        name="newPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newPassword}
                        required={true}
                        disabled={loginStatus === "loading"}
                      />
                    ) : (
                      ""
                    )
                  }
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={loginStatus === "loading"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 20,
                      marginTop: 20,
                      borderRadius: 8,
                    }}
                  >
                    Delete account
                  </Button>

                  <Box
                    paddingX={4}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ fontSize: 12 }}>
                      <Link
                        href="/terms"
                        underline="always"
                        style={{ fontSize: 12 }}
                      >
                        Terms & Conditions
                      </Link>{" "}
                      and{" "}
                      <Link
                        href="/privacy"
                        underline="always"
                        style={{ fontSize: 12 }}
                      >
                        {" "}
                        Privacy Policy
                      </Link>
                    </Typography>
                  </Box>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Paper>

      <span>{loginChallenge}</span>

      <div>
        {loginStatus === "failed" ? (
          <p>{loginErrorMessage || "An unknown error occurred"}</p>
        ) : (
          ""
        )}
      </div>
    </Box>
  )
}
